import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useMeterialList() {
  const type_Data = ref([]);
  const category_Data = ref([]);
  const group_Data = ref([]);


  const searchQuery = ref('');
  const selected_group = ref(0);
  const selected_category = ref(0);
  const selected_type = ref(0);
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

      { field: 'id', label: 'id', sortable: false, hidden:true },
        { field: 'title', label: 'ชื่อ', sortable: false },

        { field: 'group_name', label: 'กลุ่ม', sortable: false },
        { field: 'category_name', label: 'หมวดหมู่', sortable: false },
        { field: 'type_name', label: 'ประเภท', sortable: false },
        
        { field: 'price', label: 'ราคาบริการ', sortable: false },
        { field: 'wage', label: 'ค่าแพทย์', sortable: false },
        { field: 'commission', label: 'ค่ามือพนักงาน', sortable: false },


        { field: 'is_active', label: 'สถานะ', sortable: false },
        { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);
    const Category_Data = ref([]);
    const Type_Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    console.log('branch_id',branch_id);
    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_group,selected_category,selected_type,selected_status], () => {
      fetchData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-meterial/fetchData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            category_id:selected_category.value,
            type_id:selected_type.value,
            is_active:selected_status.value,
           // branch_id:branch_id,
            order_by:'meterial.id DESC'
         
          })
          .then(response => {
          
            let data = response.data.data;
            response.data.data.forEach((data) => data.balance = data.stock_in - data.stock_out);

            Data.value = response.data.data;
            console.log('meterial meterial value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching meterial' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

    const fetchGroupData  = async () => { 
      group_Data.value  = [];
      await store.dispatch('app-meterial/fetchGroupData',{
        is_active:1,
      })
      .then(response => {

      group_Data.value  = response.data.data
      console.log('group_Data value',group_Data.value);

      })
      .catch((error) => {
      console.log('error',error);
      })
  }

  const fetchCategoryData  = async () => { 

    type_Data.value = [];
    category_Data.value  = [];
    await store.dispatch('app-meterial/fetchCategoryData',{
        is_active:1,
       //branch_id:branch_id,
      })
      .then(response => {

      response.data.data.forEach((data) => {
        if(data.group_id == 1){category_Data.value.push(data)}
        else if(data.group_id == 2){type_Data.value.push(data)}
      });

      console.log('category_Data value',response.data.data);
      
      })
      .catch((error) => {
      console.log('error',error);
      })
  }

  
  fetchGroupData();
  fetchCategoryData();
  fetchData();

  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-meterial/deleteData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveMeterialVariant = status =>{
    if (status == 'วัสดุ') return 'dark'
    else if (status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveMeterialGroupnameVariant = status =>{
    if (status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 'บริการ') return 'SmileIcon'
    return 'light'
  }
    return {
        fetchData,
        tableColumns,
        type_Data,
        group_Data,
        category_Data,
        deleteData,
        Total,
        Data,
        Type_Data,
        pageLength,
        dir,
        searchQuery,
        selected_group,
        selected_category,
        selected_type,
        selected_status,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveMeterialVariant,
        resolveMeterialGroupnameVariant
    }
}